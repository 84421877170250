import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white dark:bg-nucleus-darkerBlue p-6" }
const _hoisted_2 = { class: "text-xl mb-6" }
const _hoisted_3 = { class: "text-xl mb-4" }
const _hoisted_4 = { class: "text-sm text-nucleus-grey mb-6" }
const _hoisted_5 = { class: "font-bold mb-2" }
const _hoisted_6 = { class: "list-disc list-inside pl-4" }
const _hoisted_7 = { class: "text-xl mb-6" }
const _hoisted_8 = { class: "text-xl mb-2" }
const _hoisted_9 = { class: "mb-6" }
const _hoisted_10 = { class: "text-xl mb-6" }
const _hoisted_11 = { class: "mb-6" }
const _hoisted_12 = { class: "text-sm text-nucleus-grey mb-6" }
const _hoisted_13 = { class: "font-bold mb-2" }
const _hoisted_14 = { class: "list-disc list-inside pl-4" }
const _hoisted_15 = { class: "mt-6 italic" }
const _hoisted_16 = {
  key: 5,
  class: "text-center"
}
const _hoisted_17 = { class: "mb-4" }
const _hoisted_18 = {
  key: 6,
  class: "text-center"
}
const _hoisted_19 = { class: "mb-4" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { class: "mt-6 italic" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderShadow = _resolveComponent("HeaderShadow")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_TextLinkBasic = _resolveComponent("TextLinkBasic")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_ErrorBox = _resolveComponent("ErrorBox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HeaderShadow),
    _createVNode(_component_Logo),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loginFlow === 'login')
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("pages.signIn.title")), 1),
            _createVNode(_component_Input, {
              value: _ctx.email,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
              "update:value": _ctx.validateEmail(_ctx.email),
              "input-type": "email",
              label: _ctx.$t('inputs.email.label'),
              id: "email",
              icon: "email",
              title: _ctx.$t('titles.emailIcon'),
              placeholder: _ctx.$t('inputs.email.placeholder'),
              maxLength: _ctx.inputMaxLengths.email,
              required: "",
              error: _ctx.error.email
            }, null, 8, ["value", "update:value", "label", "title", "placeholder", "maxLength", "error"]),
            _createVNode(_component_Input, {
              value: _ctx.password,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
              "input-type": _ctx.passwordInputType,
              onUpdateInputType: _cache[2] || (_cache[2] = ($event: any) => (
            (_ctx.passwordInputType = $event.type), (_ctx.passwordIcon = $event.icon)
          )),
              label: _ctx.$t('inputs.password.label'),
              id: "password",
              title: _ctx.$t('titles.showHidePassword'),
              icon: _ctx.passwordIcon,
              placeholder: _ctx.$t('inputs.password.placeholder'),
              maxLength: _ctx.inputMaxLengths.password,
              required: "",
              error: _ctx.error.password
            }, null, 8, ["value", "input-type", "label", "title", "icon", "placeholder", "maxLength", "error"]),
            _createVNode(_component_Button, {
              type: "submit",
              class: "mb-4",
              name: _ctx.$t('buttons.signIn')
            }, null, 8, ["name"]),
            _createVNode(_component_TextLinkBasic, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.loginFlow = 'forgotPasswordConfirm')),
              name: _ctx.$t('pages.signIn.linkForgottenPassword'),
              class: "text-nucleus-primaryBlue hover:text-nucleus-primaryMouseOver"
            }, null, 8, ["name"])
          ], 32))
        : _createCommentVNode("", true),
      (_ctx.loginFlow === 'newPasswordRequired')
        ? (_openBlock(), _createElementBlock("form", {
            key: 1,
            onSubmit: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitNewPassword && _ctx.submitNewPassword(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("pages.password.titleSubmitNewPassword")), 1),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("pages.password.titlePasswordRequirements")), 1),
              _createElementVNode("ul", _hoisted_6, [
                _createElementVNode("li", null, _toDisplayString(_ctx.$t("pages.password.requirements[0]")), 1),
                _createElementVNode("li", null, _toDisplayString(_ctx.$t("pages.password.requirements[1]")), 1),
                _createElementVNode("li", null, _toDisplayString(_ctx.$t("pages.password.requirements[2]")) + " (^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + =) ", 1),
                _createElementVNode("li", null, _toDisplayString(_ctx.$t("pages.password.requirements[3]")), 1),
                _createElementVNode("li", null, _toDisplayString(_ctx.$t("pages.password.requirements[4]")), 1)
              ])
            ]),
            _createVNode(_component_Input, {
              value: _ctx.newPassword,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newPassword) = $event)),
              "input-type": _ctx.newPasswordInputType,
              "update:value": _ctx.validatePassword(_ctx.newPassword),
              onUpdateInputType: _cache[6] || (_cache[6] = ($event: any) => (
            (_ctx.newPasswordInputType = $event.type),
              (_ctx.newPasswordIcon = $event.icon)
          )),
              label: _ctx.$t('inputs.newPassword.label'),
              id: "new-password",
              title: _ctx.$t('titles.showHidePassword'),
              icon: _ctx.newPasswordIcon,
              placeholder: _ctx.$t('inputs.newPassword.placeholder'),
              maxLength: _ctx.inputMaxLengths.password,
              error: _ctx.error.newPassword
            }, null, 8, ["value", "input-type", "update:value", "label", "title", "icon", "placeholder", "maxLength", "error"]),
            _createVNode(_component_Input, {
              value: _ctx.newPasswordConfirm,
              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newPasswordConfirm) = $event)),
              "input-type": _ctx.newPasswordConfirmInputType,
              "update:value": _ctx.validatePasswordConfirm(_ctx.newPasswordConfirm),
              onUpdateInputType: _cache[8] || (_cache[8] = ($event: any) => (
            (_ctx.newPasswordConfirmInputType = $event.type),
              (_ctx.newPasswordConfirmIcon = $event.icon)
          )),
              label: _ctx.$t('inputs.newPasswordConfirm.label'),
              id: "confirm-new-password",
              title: _ctx.$t('titles.showHidePassword'),
              icon: _ctx.newPasswordConfirmIcon,
              placeholder: _ctx.$t('inputs.newPasswordConfirm.placeholder'),
              maxLength: _ctx.inputMaxLengths.password,
              error: _ctx.error.newPasswordConfirm
            }, null, 8, ["value", "input-type", "update:value", "label", "title", "icon", "placeholder", "maxLength", "error"]),
            _createVNode(_component_Button, {
              type: "submit",
              name: _ctx.$t('buttons.submitNewPassword'),
              disabled: 
            !_ctx.validatePassword(_ctx.newPassword) ||
            !_ctx.validatePasswordConfirm(_ctx.newPasswordConfirm)
          
            }, null, 8, ["name", "disabled"])
          ], 32))
        : _createCommentVNode("", true),
      (_ctx.loginFlow === 'phoneVerification')
        ? (_openBlock(), _createElementBlock("form", {
            key: 2,
            onSubmit: _cache[12] || (_cache[12] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitVerification && _ctx.submitVerification(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("pages.signIn.titleVerifyPhone")), 1),
            _createVNode(_component_Input, {
              value: _ctx.code,
              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.code) = $event)),
              "input-type": "number",
              label: 
            _ctx.$t('inputs.verificationCode.label', {
              phoneNumber: _ctx.userObject.attributes.phone_number,
            })
          ,
              id: "code",
              placeholder: _ctx.$t('inputs.verificationCode.placeholder'),
              maxLength: _ctx.inputMaxLengths.code
            }, null, 8, ["value", "label", "placeholder", "maxLength"]),
            _createVNode(_component_Button, {
              type: "submit",
              name: _ctx.$t('buttons.verify'),
              class: "mb-5"
            }, null, 8, ["name"]),
            _createElementVNode("div", {
              onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.sendVerification())),
              class: "inline text-nucleus-primaryBlue hover:text-nucleus-primaryMouseOver underline transition text-sm cursor-pointer"
            }, _toDisplayString(_ctx.$t("pages.signIn.resendVerificationSMS")), 1)
          ], 32))
        : _createCommentVNode("", true),
      (_ctx.loginFlow === 'forgotPasswordConfirm')
        ? (_openBlock(), _createElementBlock("form", {
            key: 3,
            onSubmit: _cache[14] || (_cache[14] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.forgotPasswordConfirm && _ctx.forgotPasswordConfirm(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("pages.signIn.forgottenPassword")), 1),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("pages.signIn.confirmEmail")), 1),
            _createVNode(_component_Input, {
              value: _ctx.confirmEmail,
              "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.confirmEmail) = $event)),
              "update:value": _ctx.validateEmail(_ctx.confirmEmail),
              "input-type": "email",
              label: _ctx.$t('inputs.emailConfirm.label'),
              id: "confirm-email",
              title: _ctx.$t('titles.emailIcon'),
              icon: "email",
              placeholder: _ctx.$t('inputs.emailConfirm.placeholder'),
              maxLength: _ctx.inputMaxLengths.email,
              error: _ctx.error.email
            }, null, 8, ["value", "update:value", "label", "title", "placeholder", "maxLength", "error"]),
            _createVNode(_component_Button, {
              type: "submit",
              class: "mb-4",
              name: _ctx.$t('buttons.confirmEmail'),
              disabled: !_ctx.validateEmail(_ctx.confirmEmail)
            }, null, 8, ["name", "disabled"])
          ], 32))
        : _createCommentVNode("", true),
      (_ctx.loginFlow === 'forgotPasswordChange')
        ? (_openBlock(), _createElementBlock("form", {
            key: 4,
            onSubmit: _cache[21] || (_cache[21] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.forgotPasswordChange && _ctx.forgotPasswordChange(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("pages.password.titleChangePassword")), 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("pages.password.subHeading")), 1),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("pages.password.titlePasswordRequirements")), 1),
              _createElementVNode("ul", _hoisted_14, [
                _createElementVNode("li", null, _toDisplayString(_ctx.$t("pages.password.requirements[0]")), 1),
                _createElementVNode("li", null, _toDisplayString(_ctx.$t("pages.password.requirements[1]")), 1),
                _createElementVNode("li", null, _toDisplayString(_ctx.$t("pages.password.requirements[2]")) + " (^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + =) ", 1),
                _createElementVNode("li", null, _toDisplayString(_ctx.$t("pages.password.requirements[3]")), 1),
                _createElementVNode("li", null, _toDisplayString(_ctx.$t("pages.password.requirements[4]")), 1)
              ])
            ]),
            _createVNode(_component_Input, {
              value: _ctx.code,
              "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.code) = $event)),
              "input-type": "number",
              label: _ctx.$t('inputs.verificationCodeBasic.label'),
              id: "code",
              placeholder: _ctx.$t('inputs.verificationCodeBasic.placeholder'),
              maxLength: _ctx.inputMaxLengths.code
            }, null, 8, ["value", "label", "placeholder", "maxLength"]),
            _createVNode(_component_Input, {
              value: _ctx.newPassword,
              "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.newPassword) = $event)),
              autocomplete: "new-password",
              "input-type": _ctx.newPasswordInputType,
              "update:value": _ctx.validatePassword(_ctx.newPassword),
              onUpdateInputType: _cache[17] || (_cache[17] = ($event: any) => (
            (_ctx.newPasswordInputType = $event.type),
              (_ctx.newPasswordIcon = $event.icon)
          )),
              label: _ctx.$t('inputs.newPassword.label'),
              id: "new-password",
              title: _ctx.$t('titles.showHidePassword'),
              icon: _ctx.newPasswordIcon,
              placeholder: _ctx.$t('inputs.newPassword.placeholder'),
              maxLength: _ctx.inputMaxLengths.password,
              error: _ctx.error.newPassword
            }, null, 8, ["value", "input-type", "update:value", "label", "title", "icon", "placeholder", "maxLength", "error"]),
            _createVNode(_component_Input, {
              value: _ctx.newPasswordConfirm,
              "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.newPasswordConfirm) = $event)),
              autocomplete: "new-password",
              "input-type": _ctx.newPasswordConfirmInputType,
              "update:value": _ctx.validatePasswordConfirm(_ctx.newPasswordConfirm),
              onUpdateInputType: _cache[19] || (_cache[19] = ($event: any) => (
            (_ctx.newPasswordConfirmInputType = $event.type),
              (_ctx.newPasswordConfirmIcon = $event.icon)
          )),
              label: _ctx.$t('inputs.newPasswordConfirm.label'),
              id: "confirm-new-password",
              title: _ctx.$t('titles.showHidePassword'),
              icon: _ctx.newPasswordConfirmIcon,
              placeholder: _ctx.$t('inputs.newPasswordConfirm.placeholder'),
              maxLength: _ctx.inputMaxLengths.password,
              error: _ctx.error.newPasswordConfirm
            }, null, 8, ["value", "input-type", "update:value", "label", "title", "icon", "placeholder", "maxLength", "error"]),
            _createVNode(_component_Button, {
              type: "submit",
              name: _ctx.$t('buttons.submitNewPassword'),
              disabled: 
            !_ctx.validatePassword(_ctx.newPassword) ||
            !_ctx.validatePasswordConfirm(_ctx.newPasswordConfirm) ||
            !_ctx.required(_ctx.code)
          
            }, null, 8, ["name", "disabled"]),
            _createElementVNode("div", _hoisted_15, [
              _createTextVNode(_toDisplayString(_ctx.$t("pages.signIn.didntReceiveVerification")) + " ", 1),
              _createElementVNode("span", {
                onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.loginFlow = 'forgotPasswordConfirm')),
                class: "underline cursor-pointer text-nucleus-primaryBlue"
              }, _toDisplayString(_ctx.$t("pages.signIn.linkEnterEmailAgain")), 1)
            ])
          ], 32))
        : _createCommentVNode("", true),
      (_ctx.loginFlow === 'passwordChanged')
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("pages.signIn.passwordChangeSuccessful")), 1),
            _createVNode(_component_Button, {
              onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.loginFlow = 'login')),
              name: _ctx.$t('buttons.backToSignIn')
            }, null, 8, ["name"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.loginFlow === 'logged in')
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createTextVNode(_toDisplayString(_ctx.$t("inputs.verificationCode.label", {
              name: _ctx.userObject.attributes.name,
              email: _ctx.userObject.attributes.email,
            })) + " ", 1),
              (_ctx.localAdmin)
                ? (_openBlock(), _createElementBlock("strong", _hoisted_20, _toDisplayString(_ctx.$t("global.defaults.localAdmin")), 1))
                : _createCommentVNode("", true),
              (_ctx.serviceAdmin)
                ? (_openBlock(), _createElementBlock("strong", _hoisted_21, _toDisplayString(_ctx.$t("global.defaults.serviceAdmin")), 1))
                : _createCommentVNode("", true)
            ]),
            (_ctx.serviceAdmin)
              ? (_openBlock(), _createBlock(_component_ButtonLink, {
                  key: 0,
                  to: "/service-admin",
                  name: _ctx.$t('buttons.viewTenants')
                }, null, 8, ["name"]))
              : _createCommentVNode("", true),
            (_ctx.localAdmin)
              ? (_openBlock(), _createBlock(_component_ButtonLink, {
                  key: 1,
                  to: "/local-admin",
                  name: _ctx.$t('buttons.viewSessions')
                }, null, 8, ["name"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_22, [
              _createTextVNode(_toDisplayString(_ctx.$t("pages.signIn.notYou")) + " ", 1),
              _createElementVNode("span", {
                onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.logout())),
                class: "underline cursor-pointer text-nucleus-primaryBlue"
              }, _toDisplayString(_ctx.$t("global.defaults.logOut")), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.errorHeading || _ctx.errorContent)
        ? (_openBlock(), _createBlock(_component_ErrorBox, {
            key: 7,
            heading: _ctx.errorHeading,
            content: _ctx.errorContent,
            fixed: _ctx.errorFixed,
            onCloseError: _cache[24] || (_cache[24] = ($event: any) => {
          _ctx.errorHeading = '';
          _ctx.errorContent = '';
        })
          }, null, 8, ["heading", "content", "fixed"]))
        : _createCommentVNode("", true)
    ])
  ]))
}