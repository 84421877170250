import { i18n } from "../main";

enum ErrorType {
  UserNotFound,
  NotAuthorized,
  ExpiredCode,
  Unknown,
  CodeMismatch,
}

/**
 * List of overriding error messages for certain cases - if no message is in this list
 * for a given error type the
 */
// prettier-ignore
const errorMessageOverrides = new Map<ErrorType, string>([
  [ErrorType.UserNotFound, "error.user.notFound"],
  [ErrorType.NotAuthorized, "error.auth.invalidCredentials"],
  [ErrorType.ExpiredCode, "error.userVerification.expiredCode"],
  [ErrorType.CodeMismatch, "error.userVerification.invalidCode"],
]);

const cognitoErrorTypeMap = new Map<string, ErrorType>([
  ["UserNotFoundException", ErrorType.UserNotFound],
  ["NotAuthorizedException", ErrorType.NotAuthorized],
  ["ExpiredCodeException", ErrorType.ExpiredCode],
  ["CodeMismatchException", ErrorType.CodeMismatch],
]);

function cognitoErrorType(error: Record<string, string>): ErrorType {
  return cognitoErrorTypeMap.get(error.name) || ErrorType.Unknown;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function errorFromCognito(error: any): string {
  if (error instanceof Object) {
    const type = cognitoErrorType(error);
    const messageKey = errorMessageOverrides.get(type) || error?.messageKey;
    console.log("Key", messageKey);
    return messageKey
      ? i18n.global.t(messageKey)
      : error?.message || error.toString();
  } else {
    return error.toString();
  }
}
