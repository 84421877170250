
import { Options, Vue } from "vue-class-component";
import HeaderShadow from "@/components/utils/HeaderShadow.vue";
import Input from "@/components/utils/Input.vue";
import Button from "@/components/utils/Button.vue";
import ButtonOutlined from "@/components/utils/ButtonOutlined.vue";
import PageHeading from "@/components/utils/PageHeading.vue";
import Logo from "@/components/utils/Logo.vue";
import TextLinkBasic from "@/components/utils/TextLinkBasic.vue";
import Checkbox from "@/components/utils/Checkbox.vue";
import ButtonLink from "@/components/utils/ButtonLink.vue";
import ErrorBox from "@/components/utils/ErrorBox.vue";

import { Auth } from "aws-amplify";
import router from "@/router";
import { errorFromCognito } from "@/utils/errorMappings";

import InputMaxLengths from "@/store/data/inputMaxLengths.json";

@Options({
  components: {
    HeaderShadow,
    Input,
    Button,
    ButtonOutlined,
    PageHeading,
    Logo,
    TextLinkBasic,
    Checkbox,
    ButtonLink,
    ErrorBox,
  },
  data() {
    return {
      email: "",
      password: "",
      newPassword: "",
      newPasswordConfirm: "",
      code: "",
      confirmEmail: "",
      passwordInputType: "password",
      newPasswordInputType: "password",
      newPasswordConfirmInputType: "password",
      passwordIcon: "show",
      newPasswordIcon: "show",
      newPasswordConfirmIcon: "show",
      loginFlow: "login",
      error: {
        email: "",
        confirmEmail: "",
        password: "",
        newPassword: "",
        newPasswordConfirm: "",
      },
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
      inputMaxLengths: InputMaxLengths[0],
    };
  },
  computed: {
    userObject() {
      return this.$store.getters.getUserObject;
    },
    serviceAdmin() {
      return this.$store.getters.getServiceAdmin;
    },
    localAdmin() {
      return this.$store.getters.getLocalAdmin;
    },
    staff() {
      return this.$store.getters.getStaff;
    },
  },
  beforeMount() {
    this.checkLoginStatus(this.userObject);
  },
  mounted() {
    document.title =
      this.$t("pages.signIn.title") +
      " - " +
      this.$t("global.defaults.browserTitle");
  },
  methods: {
    validateEmail(email: string) {
      const mailformat =
        // eslint-disable-next-line no-useless-escape
        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      if (this.required(email)) {
        if (!email.match(mailformat)) {
          this.error.email = this.$t("inputs.email.errorInvalid");
          return false;
        } else {
          this.error.email = "";
          return true;
        }
      } else {
        return false;
      }
    },
    validatePassword(password: string) {
      const passwordRequirement =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[(^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=)])[a-zA-Z0-9(^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=)]{12,99}$/;
      if (this.required(password)) {
        if (!password.match(passwordRequirement)) {
          this.error.newPassword = this.$t("inputs.password.errorInvalid");
          return false;
        } else {
          this.error.newPassword = "";
          return true;
        }
      } else {
        return false;
      }
    },
    validatePasswordConfirm(password: string) {
      if (this.required(password)) {
        if (password !== this.newPassword) {
          this.error.newPasswordConfirm = this.$t("inputs.password.errorMatch");
          return false;
        } else {
          this.error.newPasswordConfirm = "";
          return true;
        }
      } else {
        return false;
      }
    },
    required(input: string, inputName: string) {
      if (input.length > 0) {
        if (inputName) this.error[inputName] = "";
        return true;
      } else {
        if (inputName)
          this.error[inputName] =
            inputName + " " + this.$t("error.validation.isRequired");
        return false;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async checkLoginStatus(user: any) {
      // Will need to refactor this function
      // console.log("User status", user);

      if (!user) {
        this.loginFlow = "login";
        return;
      }

      if (
        user.challengeName &&
        user.challengeName === "NEW_PASSWORD_REQUIRED"
      ) {
        this.loginFlow = "newPasswordRequired";
        return;
      }

      if (user.attributes.phone_number_verified === false) {
        this.loginFlow = "phoneVerification";
        return;
      }

      if (!user.challengeName) {
        this.navigateAway();
      }
    },
    navigateAway() {
      if (this.serviceAdmin) {
        router.push({ name: "serviceAdmin" });
      } else if (this.localAdmin || this.staff) {
        router.push({ name: "localAdmin" });
      } else {
        this.loginFlow = "logged in";
      }
    },
    async submitNewPassword() {
      if (
        this.validatePassword(this.newPassword) &&
        this.validatePasswordConfirm(this.newPasswordConfirm)
      ) {
        try {
          const submitNewPasswordApi = await Auth.completeNewPassword(
            this.userObject,
            this.newPassword
          );
          await this.sendVerification();
          await this.refreshUser();
          this.loginFlow = "phoneVerification";
          this.password = this.newPassword;
          this.newPassword = "";
          this.newPasswordConfirm = "";
          this.errorHeading = "";
        } catch (err) {
          this.errorHeading = errorFromCognito(err);
          console.log("ERROR", err);
        }
      }
    },
    async login() {
      // Removing all access incase a user gets logged out with out clicking the log out button
      this.removeAccess();
      if (this.validateEmail(this.email) && this.required(this.password)) {
        try {
          const loginApi = await Auth.signIn(this.email, this.password);
          await this.checkLoginStatus(loginApi);
          await this.$store.dispatch("setUserObject", loginApi);
          this.errorHeading = "";
          // console.log(loginApi);
        } catch (err) {
          console.log("ERROR", err);
          this.errorHeading = errorFromCognito(err);
        }
      }
    },
    async logout() {
      try {
        await Auth.signOut();
        this.removeAccess();
        await this.checkLoginStatus(this.userObject);
        router.go(0);
      } catch (err) {
        console.log("ERROR", err);
      }
    },
    removeAccess() {
      this.$store.dispatch("setUserObject", null);
      this.$store.dispatch("setRoles", []);
    },
    async refreshUser() {
      const updatedUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      await this.$store.dispatch("setUserObject", updatedUser);
    },
    async sendVerification() {
      try {
        const resend = await Auth.verifyCurrentUserAttribute("phone_number");
        console.log(resend);
        this.errorHeading = "";
      } catch (err) {
        console.log("USER NOT LOGGED IN", err);
        this.errorHeading = errorFromCognito(err);
      }
    },
    async submitVerification() {
      try {
        const verifyResult = await Auth.verifyCurrentUserAttributeSubmit(
          "phone_number",
          this.code
        );
        console.log(verifyResult);
        await this.refreshUser();
        router.go(0);
        this.loginFlow = "logged in";
        this.code = "";
        this.errorHeading = "";
        this.navigateAway();
      } catch (err) {
        console.log("Phone number verification failed", err);
        this.errorHeading = errorFromCognito(err);
      }
    },
    async forgotPasswordConfirm() {
      if (this.validateEmail(this.confirmEmail)) {
        try {
          const submitForgotPasswordConfirmApi = await Auth.forgotPassword(
            this.confirmEmail
          );
          console.log(submitForgotPasswordConfirmApi);
          this.loginFlow = "forgotPasswordChange";
          this.errorHeading = "";
        } catch (err) {
          this.errorHeading = errorFromCognito(err);
        }
      }
    },
    async forgotPasswordChange() {
      if (
        this.validatePassword(this.newPassword) &&
        this.validatePasswordConfirm(this.newPasswordConfirm)
      ) {
        try {
          const submitForgotPasswordChangeApi = await Auth.forgotPasswordSubmit(
            this.confirmEmail,
            this.code,
            this.newPassword
          );
          console.log(submitForgotPasswordChangeApi);
          this.loginFlow = "passwordChanged";
          this.errorHeading = "";
        } catch (err) {
          this.errorHeading = errorFromCognito(err);
        }
      }
    },
  },
})
export default class Navigation extends Vue {}
